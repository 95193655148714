.registration{
  padding: 10vh 10vw;
  .team{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    .form-floating{
      width: calc(50% - 5px);
      min-width: 150px;
    }
  }
  .progressBar{
    position: fixed;
    bottom: 0px;
    left: 10px;
    right: 10px;
    background-color: #fff;
    z-index: 10;
    text-align: center;
    padding: 10px 0;
  }
}

@media only screen and (min-width: 800px) {
  .registration{
    .team{
      .form-floating{
        width: calc(33% - 5px);
      }
    }
  }
}

.registration-spinner{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  background-color: #ffffff8f;
  flex-direction: column;
}

a{
  text-decoration: underline !important;
  margin: 0 2px;
  cursor: pointer;
}

.registration-schema-wrapper{
  margin-top: 20px;
}

.fade-enter{
  opacity: 0;
}
.fade-exit{
  opacity: 1;
}
.fade-enter-active{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 500ms;
}
