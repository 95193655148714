.home{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 20vw;

  a{
    text-decoration: none;
    color: black;
  }
  .card{
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .card:hover{
    background-color: #d8d8d8;
  }
}
